import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Combobox, Flex, Text, useCombobox } from "@mantine/core";
import { format } from "date-fns/format";
import identity from "lodash/identity";
import {
  useDebouncedValue,
  useDisclosure,
  useInputState,
} from "@mantine/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";

import { CandidateDrawer } from "@/components/drawer";
import { ComboboxTargetInput } from "@/components/ui/combobox-target-input";
import {
  BadgeStyle,
  UniversalSearchResult,
  type UniversalSearchResultProps,
} from "@/components/universal-search/universal-search-result";
import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import { useUniversalSearchQuery } from "@/hooks/queries/mixed.queries";
import {
  SearchResultType,
  type UniversalSearchResult as UniversalSearchResultType,
} from "@/types/universal-search";
import type { Maybe } from "@/types/utils";
import { getCodeForHighlightColor } from "@/utils/ui";

const generateResultBreadcrumbs = (result: UniversalSearchResultType) => {
  const combinedName = `${result.screenerName} | ${result.internalName}`;

  switch (result.type) {
    case SearchResultType.Location:
      return [combinedName, "Locations"];
    case SearchResultType.Screener:
      return ["Screeners"];
    case SearchResultType.Session:
      return [combinedName, result.location, "Screenings"];
  }
};

const formatSearchResultProps = (
  result: UniversalSearchResultType,
): Omit<UniversalSearchResultProps, "isLastOption" | "onClick"> => {
  const formatCountString = (str: string, count = 0) =>
    `${count} ${str}${count === 1 ? "" : "s"}`;

  switch (result.type) {
    case SearchResultType.Location: {
      const isLive = result.locationStatus;

      return {
        badgeContent: isLive ? "Live" : "Paused",
        badgeStyle: isLive ? BadgeStyle.Active : BadgeStyle.Inactive,
        breadcrumbs: generateResultBreadcrumbs(result),
        href: `/screener-location/${result.recordId}`,
        recordId: result.recordId,
        subTitle: formatCountString("screening", result.sessionCount),
        title: result.location,
      };
    }
    case SearchResultType.Screener: {
      const statusBadgeMap = {
        live: BadgeStyle.Active,
        mixed: BadgeStyle.Mixed,
        paused: BadgeStyle.Inactive,
      };

      return {
        badgeContent: result.screenerStatus,
        badgeStyle: statusBadgeMap[result.screenerStatus],
        breadcrumbs: generateResultBreadcrumbs(result),
        href: `/screener/${result.recordId}`,
        recordId: result.recordId,
        subTitle: `${formatCountString("screening", result.sessionCount)} | ${formatCountString("location", result.locationCount)}`,
        title: result.screenerName,
      };
    }
    case SearchResultType.Session: {
      const { candidatePhone, candidateEmail } = result;

      return {
        badgeContent: format(result.sessionCreatedAt, "MMM d, y"),
        badgeStyle: BadgeStyle.Inactive,
        breadcrumbs: generateResultBreadcrumbs(result),
        highlightColor: getCodeForHighlightColor(result.highlightColor),
        recordId: result.recordId,
        subTitle:
          candidatePhone || candidateEmail
            ? [candidatePhone, candidateEmail].filter(identity).join(" | ")
            : "",
        title: result.candidateName ?? "",
      };
    }
  }
};

const INPUT_WIDTH_PX = 340;

export const UniversalSearch = ({ close }: { close?: () => void }) => {
  const track = useTrackAnalytics();
  const [shouldSendAnalytics, setShouldSendAnalytics] = useState(true);
  const [opened, { toggle }] = useDisclosure(false);
  const combobox = useCombobox({ opened });
  const [search, setSearch] = useInputState("");
  const [debounced] = useDebouncedValue(search, 200);
  const [selectedSession, setSelectedSession] =
    useState<Maybe<{ email?: string; sessionId: string }>>();
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const { data: results, isFetched } = useUniversalSearchQuery(debounced, {
    enabled: debounced.length >= 3,
  });

  const trackResultClicked = useCallback(
    (recordId: string, resultType: SearchResultType) => {
      track("Universal Search Result Clicked", {
        selected_result_id: recordId,
        selected_result_type: resultType,
      });
    },
    [track],
  );

  const onSessionClick = useCallback(
    (sessionId: string, email?: string) => {
      trackResultClicked(sessionId, SearchResultType.Session);
      setSelectedSession({ email, sessionId });
      setSearch("");
      openDrawer();
    },
    [openDrawer, setSearch, trackResultClicked],
  );

  const onDrawerClose = useCallback(() => {
    setSelectedSession(undefined);
    closeDrawer();
  }, [closeDrawer]);

  const resultsSection = useMemo(() => {
    if (results && results.length > 0) {
      return (
        <Combobox.Options>
          {results?.map((result, index) => (
            <UniversalSearchResult
              {...formatSearchResultProps(result)}
              isLastOption={index === results?.length - 1}
              key={result.recordId}
              onClick={
                result.type === SearchResultType.Session
                  ? () => onSessionClick(result.recordId, result.candidateEmail)
                  : () => trackResultClicked(result.recordId, result.type)
              }
            />
          ))}
        </Combobox.Options>
      );
    }

    if (!results?.length && isFetched) {
      return (
        <Flex p={18} w={INPUT_WIDTH_PX}>
          <Text className="text-uw-gray-5" size="sm">
            No matching results
          </Text>
        </Flex>
      );
    }
  }, [isFetched, onSessionClick, results, trackResultClicked]);

  useEffect(() => {
    if (
      (search.length >= 3 && !combobox.dropdownOpened) ||
      (search.length === 0 && combobox.dropdownOpened)
    ) {
      toggle();
    }
  }, [combobox, search, toggle]);

  // We need to track when a user makes use of the universal search, but due to
  // the real-time search implementation it is non-trivial to determine what
  // constitutes a "use". In this case we treat any time a user enters text in
  // the input while it is empty as a new use.
  useEffect(() => {
    if (shouldSendAnalytics && search.length >= 1) {
      setShouldSendAnalytics(false);
      track("Universal Search Initiated");
    }

    if (!shouldSendAnalytics && search.length === 0) {
      setShouldSendAnalytics(true);
    }
  }, [search, shouldSendAnalytics, track]);

  return (
    <>
      <Combobox
        position="bottom-end"
        transitionProps={{ duration: 300, transition: "pop-top-right" }}
        store={combobox}
        width="max-content"
      >
        <ComboboxTargetInput
          leftSection={<FontAwesomeIcon icon={faMagnifyingGlass} />}
          ml={5}
          onChange={setSearch}
          onClear={close}
          placeholder="Search candidate, location, or job..."
          showClearButton
          styles={{ wrapper: { width: INPUT_WIDTH_PX } }}
          value={search}
        />
        <Combobox.Dropdown
          className="max-h-[733px] overflow-y-auto rounded-md"
          p={0}
          style={{ boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.1)" }}
        >
          {resultsSection}
        </Combobox.Dropdown>
      </Combobox>
      <CandidateDrawer
        email={selectedSession?.email}
        onClose={onDrawerClose}
        opened={drawerOpened}
        sessionId={selectedSession?.sessionId}
        short
      />
    </>
  );
};
