"use client";

import { faArrowRightFromBracket } from "@fortawesome/pro-solid-svg-icons/faArrowRightFromBracket";
import { faChartLine } from "@fortawesome/pro-solid-svg-icons/faChartLine";
import { faComment } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFileArrowUp } from "@fortawesome/pro-solid-svg-icons/faFileArrowUp";
import { faGear } from "@fortawesome/pro-solid-svg-icons/faGear";
import { faBookmark } from "@fortawesome/pro-solid-svg-icons/faBookmark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Button,
  Flex,
  Image,
  Text,
  Tooltip,
  Transition,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { twMerge } from "tailwind-merge";

import { AccountModal } from "@/components/account-modal";
import CsvModal from "@/components/csvModal/csvModal";
import { useUserContext } from "@/context/user.context";
import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import { closeExtension } from "@/utils/extension";
import { LAYOUT_DIMENSIONS } from "@/utils/ui";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { UniversalSearch } from "../universal-search";

const getSharedButtonProps = (isCurrentLink = false) => ({
  variant: isCurrentLink ? "filled" : "transparent",
  fw: 600,
  px: 12,
  size: "xs",
  bg: isCurrentLink ? "#EFEAFA" : "transparent",
  c: "purple.5",
  styles: {
    inner: {
      fontSize: 14,
      fontWeight: 700,
    },
    root: {
      borderRadius: 20,
      height: 32,
    },
  },
});

const getSharedActionIconProps = () => ({
  variant: "filled",
  size: "md",
  bg: "gray.0",
  c: "gray.7",
  styles: {
    root: {
      borderRadius: 20,
      height: 32,
      width: 32,
    },
  },
});

interface HeaderProps {
  hideAccountButtons?: boolean;
}

export const Header = ({ hideAccountButtons = false }: HeaderProps) => {
  const track = useTrackAnalytics();
  const pathname = usePathname();
  const { logOut, user } = useUserContext();
  const [
    accountModalOpened,
    { close: closeAccountModal, open: openAccountModal },
  ] = useDisclosure(false);
  const [universalSearchOpened, { toggle: toggleUniversalSearch }] =
    useDisclosure(false);
  const [csvModalOpened, { open: openCsvModal, close: closeCsvModal }] =
    useDisclosure(false);

  return (
    <header
      className={twMerge([
        "bg-white",
        "border-b-2",
        "border-gray-2000",
        "flex",
        "items-center",
        "justify-between",
        "px-[18px]",
        "w-full",
        "z-40",
        "relative",
      ])}
      style={{ height: LAYOUT_DIMENSIONS.HEADER_HEIGHT_PX }}
    >
      <div className="flex gap-6 items-center">
        <Link href="/">
          <Image src="/assets/logo.svg" alt="Logo" h={20} />
        </Link>
        <Text size="sm" fw={600}>
          {user?.company.name || ""}
        </Text>
      </div>
      <Flex align="center" gap={8}>
        {user && (
          <>
            {!hideAccountButtons && (
              <>
                {user.internal && (
                  <Button
                    {...getSharedButtonProps()}
                    onClick={openCsvModal}
                    rightSection={<FontAwesomeIcon icon={faFileArrowUp} />}
                  >
                    Upload CSV
                  </Button>
                )}
                <Button
                  {...getSharedButtonProps(
                    pathname === "/" ||
                      pathname.startsWith("/screener") ||
                      pathname.startsWith("/screener-location"),
                  )}
                  component={Link}
                  href="/"
                  rightSection={<FontAwesomeIcon icon={faComment} />}
                >
                  Interviews
                </Button>
                <Button
                  {...getSharedButtonProps(pathname === "/assigned-to/me")}
                  component={Link}
                  href="/assigned-to/me"
                  rightSection={<FontAwesomeIcon icon={faBookmark} />}
                >
                  Assigned
                </Button>
                <Button
                  {...getSharedButtonProps(pathname === "/analytics")}
                  component={Link}
                  href="/analytics"
                  onClick={() => track("Analytics Link Clicked")}
                  rightSection={<FontAwesomeIcon icon={faChartLine} />}
                >
                  Analytics
                </Button>
                <Button
                  {...getSharedButtonProps()}
                  onClick={() => {
                    track("Account Button Clicked");
                    openAccountModal();
                  }}
                  rightSection={<FontAwesomeIcon icon={faGear} />}
                >
                  Account
                </Button>
              </>
            )}
          </>
        )}
      </Flex>
      <Flex
        align="center"
        gap={12}
        style={{
          opacity: universalSearchOpened ? 0 : 1,
          transition: "opacity 0.3s ease",
          visibility: universalSearchOpened ? "hidden" : "visible",
        }}
      >
        <Tooltip label="Search">
          <ActionIcon
            onClick={() => toggleUniversalSearch()}
            aria-label="search"
            {...getSharedActionIconProps()}
          >
            <FontAwesomeIcon icon={faSearch} size="sm" />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Log out">
          <ActionIcon
            component={Link}
            href="/auth/login?logout=true"
            onClick={() => logOut()}
            aria-label="logout"
            {...getSharedActionIconProps()}
          >
            <FontAwesomeIcon icon={faArrowRightFromBracket} size="sm" />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Close Upwage">
          <ActionIcon
            onClick={() => closeExtension()}
            aria-label="close"
            {...getSharedActionIconProps()}
          >
            <FontAwesomeIcon icon={faXmark} size="sm" />
          </ActionIcon>
        </Tooltip>
      </Flex>

      <Transition
        mounted={universalSearchOpened}
        transition="slide-left"
        duration={300}
        timingFunction="ease"
      >
        {(styles) => (
          <div
            style={{
              ...styles,
              position: "absolute",
              right: 16,
              top: 0,
              height: "100%",
              display: "flex",
              alignItems: "center",
              zIndex: 50,
            }}
          >
            <UniversalSearch close={toggleUniversalSearch} />
          </div>
        )}
      </Transition>

      <AccountModal onClose={closeAccountModal} opened={accountModalOpened} />
      {user?.internal && (
        <CsvModal onClose={closeCsvModal} opened={csvModalOpened} />
      )}
    </header>
  );
};
